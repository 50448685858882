import PropTypes from 'prop-types';
import React, { Component } from 'react';

export const YOUTUBE_STATE = {
    BUFFERING: 3,
    CUED: 5,
    ENDED: 0,
    PAUSED: 2,
    PLAYING: 1,
    UNSTARTED: -1
};

class YoutubePlayer extends Component {
    constructor(props) {
        super(props);

        this.youTubePlayer = null;

        this.onPlayerReady = this.onPlayerReady.bind(this);
        this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
    }


    // Life Cycle
    componentDidMount() {
        // Adds youtube iframe api script
        const tag = document.createElement('script');

        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // Creates a new youtube player once the API is ready
        const { url } = this.props;

        const videoId = url.replace(/.+\/?v(?:=|\/)([^&]+)/gi, '$1');

        window.onYouTubeIframeAPIReady = () => {
            this.youTubePlayer = new window.YT.Player('player', {
                videoId,
                host: 'https://www.youtube.com',
                playerVars: {
                    'autoplay': 1,
                    'controls': 0,
                    'loop': 1,
                    'showinfo': 0,
                    'mute': 1,
                    'playlist': videoId
                },
                events: {
                    'onReady': this.onPlayerReady,
                    'onStateChange': this.onPlayerStateChange
                }
            });
        };
    }

    UNSAFE_componentWillUnmount() {
        this.youTubePlayer.destroy();
    }


    // Helpers
    onPlayerReady(event) {
        event.target.playVideo();
    }

    onPlayerStateChange(event) {
        this.props.updatePlayerState(event.data);
    }


    // Render
    render() {
        return (
            <div className="youtube-player">
                <div id="player" className="youtube-player-frame"></div>
            </div>
        );
    }
}

YoutubePlayer.propTypes = {
    updatePlayerState: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired
};

export default YoutubePlayer;
