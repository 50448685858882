import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import homepageStructuredData from 'app/components/partials/homepage-structured-data';
import PropTypes from 'prop-types';
import Strip from 'app/components/partials/strip';
import React, { useEffect, useRef, useState } from 'react';

const Home = ({ data }) => {
    const [isStripActive, setIsStripActive] = useState(false);
    const {
        blocks,
        thumbnail,
        name: title,
        main_images: mainImages,
        short_description: description
    } = data;

    const heroCarouselRef = useRef(null);

    const checkHeroCarouselInView = () => {
        const heroCarouselOffset = heroCarouselRef.current.getBoundingClientRect();
        if (heroCarouselOffset.top < 0 && heroCarouselOffset.bottom < 0) return setIsStripActive(false);

        setIsStripActive(true);
    };

    useEffect(() => {
        checkHeroCarouselInView();
        window.addEventListener('scroll', checkHeroCarouselInView);

        return () => window.removeEventListener('scroll', checkHeroCarouselInView);
    }, []);

    return (
        <div className="content-page">
            {homepageStructuredData()}
            <Banner
                description={description}
                mainImages={mainImages}
                title={title}
                thumbnail={thumbnail}
                primary={true}
                ref={heroCarouselRef}
            />
            <ContentBlocks data={blocks} />
            <Strip isActive={isStripActive} />
        </div>
    );
}

Home.propTypes = {
    data: PropTypes.object.isRequired
};

export default Home;
