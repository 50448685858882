import PropTypes from 'prop-types';
import HeroCarousel, { VIEW } from 'app/components/partials/hero-carousel';
import React, { forwardRef } from 'react';

const Banner = ({ compact, description, innerRef, light, mainImages, title, thumbnail, primary }) => {
    const type = primary ? 'primary' : '';

    /**
     * If there is a thumbnail, should return both mobile and desktop carousels.
     * Otherwise just return the default carousel using main images.
     */
    if (thumbnail) {
        return (
            <div className="banner" ref={innerRef}>
                <HeroCarousel
                    compact={compact}
                    introduction={description}
                    images={[thumbnail]}
                    light={light}
                    title={title}
                    type={type}
                    view={VIEW.mobile}
                />
                <HeroCarousel
                    compact={compact}
                    introduction={description}
                    images={mainImages}
                    light={light}
                    title={title}
                    type={type}
                    view={VIEW.desktop}
                />
            </div>
        );
    }

    return (
        <HeroCarousel
            compact={compact}
            introduction={description}
            images={mainImages}
            light={light}
            title={title}
            type={type}
        />
    );
};

Banner.defaultProps = {
    compact: false,
    light: false,
    description: ''
};

Banner.propTypes = {
    compact: PropTypes.bool.isRequired,
    description: PropTypes.any.isRequired,
    light: PropTypes.bool.isRequired,
    mainImages: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    primary: PropTypes.bool,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ])
};


const BannerForwardingRef = forwardRef((props, ref) => <Banner innerRef={ref} {...props} />);

BannerForwardingRef.displayName = 'Banner';

export default BannerForwardingRef;
